import styled from '@emotion/styled'
import { Close } from 'app/components/Common/Close'
import { Gallery } from 'app/components/Common/Gallery'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Filter } from './Filter'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  languageCode: string
  pretitle?: string
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  languageCode,
}: Props) {
  if (!categories) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []

  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {categories.length > 1 ? (
          <Filters dial={5} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              title={useVocabularyData('all-photos', languageCode)}
              onClick={() => {
                setFilteredCategory('all')
              }}
            />
            {uniqBy(categories, 'title').map((item, index) => {
              const category = item.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              return category ? (
                <Filter
                  active={filteredCategory === category}
                  category={category}
                  key={index}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                  title={item.title}
                />
              ) : undefined
            })}
          </Filters>
        ) : null}

        <Media lessThan="desktopSmall">
          <MasonryContainer
            options={{
              columnWidth: 1,
              percentPosition: true,
              transitionDuration: 0,
            }}
          >
            {uniqBy(categories, 'title').map((list, index) => {
              const category = list.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              if (!list.thumbnails) {
                return null
              }
              return (
                <React.Fragment key={index}>
                  <Category>{list.title}</Category>
                  {list.thumbnails.map((item, index) => {
                    counter = counter + 1
                    return (
                      <Thumb
                        className={
                          filteredCategory === category ||
                          filteredCategory === 'all'
                            ? 'visible'
                            : undefined
                        }
                        data-counter={counter}
                        key={index}
                        onClick={(e) => {
                          if (!modalGalleryStatus) {
                            setLocked(true)
                            setModalGalleryStatus(true)
                          } else {
                            setLocked(false)
                            setModalGalleryStatus(false)
                          }
                          const realClickedSlide =
                            Number(
                              e.currentTarget.getAttribute('data-counter'),
                            ) - 1
                          setClickedSlide(realClickedSlide)
                          setTimeout(() => {
                            setClickedSlide(null)
                          }, 100)
                        }}
                      >
                        <LazyLoadComponent threshold={150}>
                          <Image {...item} />
                        </LazyLoadComponent>
                      </Thumb>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </MasonryContainer>
        </Media>

        <Media greaterThanOrEqual="desktopSmall">
          <MasonryContainer
            options={{
              columnWidth: 1,
              percentPosition: true,
              transitionDuration: 0,
            }}
          >
            {uniqBy(categories, 'title').map((list, index) => {
              const category = list.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              if (!list.thumbnails) {
                return null
              }
              return (
                <React.Fragment key={index}>
                  {list.thumbnails.map((item, index) => {
                    counter = counter + 1
                    return (
                      <Thumb
                        className={
                          filteredCategory === category ||
                          filteredCategory === 'all'
                            ? 'visible'
                            : undefined
                        }
                        data-counter={counter}
                        key={index}
                        onClick={(e) => {
                          if (!modalGalleryStatus) {
                            setLocked(true)
                            setModalGalleryStatus(true)
                          } else {
                            setLocked(false)
                            setModalGalleryStatus(false)
                          }
                          const realClickedSlide =
                            Number(
                              e.currentTarget.getAttribute('data-counter'),
                            ) - 1
                          setClickedSlide(realClickedSlide)
                          setTimeout(() => {
                            setClickedSlide(null)
                          }, 100)
                        }}
                      >
                        <LazyLoadComponent threshold={150}>
                          <Image {...item} />
                        </LazyLoadComponent>
                      </Thumb>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </MasonryContainer>
        </Media>

        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(0)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;
  margin-top: 10rem;
  text-align: center;

  @media (max-width: 1299px) {
    padding: 0;
    margin-top: 0;
  }
`

const Filters = styled(FlexBox)`
  margin-bottom: 3rem;
  align-items: left;
  justify-content: flex-start;

  @media (max-width: 1199px) {
    display: none;
  }
`

const MasonryContainer = styled(Masonry)<MasonryProps>`
  margin-left: -1.6rem;
  margin-bottom: -2rem;

  @media (max-width: 991px) {
    margin-left: 0;
  }
`

const Category = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding-left: 4.16vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
`
const Thumb = styled.div`
  position: relative;
  display: none;
  width: calc(24.95% - 1.6rem);
  height: 26.5vw;
  aspect-ratio: 1.33;
  margin-bottom: 1.6rem;
  margin-left: 1.6rem;
  overflow: hidden;
  cursor: pointer;

  &:nth-of-type(8n + 2),
  &:nth-of-type(8n + 4),
  &:nth-of-type(8n + 7),
  &:nth-of-type(8n + 8) {
    height: 15vw;
    aspect-ratio: 0.75;
  }

  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  &.visible {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.25s ease-in-out;
  }

  @media (max-width: 991px) {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    width: calc(49% - 0.5rem);

    height: auto !important;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s ease-out;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
